import React from 'react';
import { Card } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

const UsageCard = ({ title, color, value, maxValue, unit }) => {
  const progress = (value / maxValue) * 100;

  const progressBarContainerStyle = {
    backgroundColor: `${color}33`,
    borderRadius: '4px',
    overflow: 'hidden',
    height: '8px',
    width: '100%',
  };

  const progressBarStyle = {
    backgroundColor: color,
    height: '100%',
    width: `${progress}%`,
    borderRadius: '4px',
    transition: 'width 0.3s ease',
  };

  return (
    <Card sx={{ height: '100%', padding: { xs: '8px', sm: '16px' } }}>
      <VuiBox 
        display="flex" 
        flexDirection="column" 
        height="100%" 
        justifyContent="space-between" 
        sx={{ 
          padding: { xs: '8px', sm: '16px' }, 
          boxSizing: 'border-box',
        }}
      >
        <VuiTypography 
          variant="h6" 
          color="white" 
          mb={1} 
          sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}
        >
          {title}
        </VuiTypography>
        <VuiBox sx={{ flex: 1 }}>
          <VuiBox sx={progressBarContainerStyle}>
            <VuiBox sx={progressBarStyle} />
          </VuiBox>
        </VuiBox>
        <VuiTypography 
          variant="body2" 
          color="white" 
          mt={1} 
          sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
        >
          {value} {unit} 
        </VuiTypography>
      </VuiBox>
    </Card>
  );
};

export default UsageCard;