

export const lineChartOptionsDashboard = {
  chart: {
    toolbar: { show: false },
  },
  tooltip: { theme: "dark" },
  dataLabels: { enabled: false },
  stroke: { curve: "smooth" },
  xaxis: {
    type: "datetime",
    categories: [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ],
    labels: {
      style: { colors: "#c8cfca", fontSize: "10px" },
    },
    axisBorder: { show: false },
    axisTicks: { show: false },
  },
  yaxis: {
    labels: { style: { colors: "#c8cfca", fontSize: "10px" } },
  },
  legend: { show: false },
  grid: {
    strokeDashArray: 5,
    borderColor: "#56577A",
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "vertical",
      shadeIntensity: 0,
      inverseColors: true,
      opacityFrom: 0.8,
      opacityTo: 0,
    },
  },
  colors: ["#14B8A6", "#3B82F6", "#6366F1", "#EC4899", "#F59E0B", "#FACC15"],
};