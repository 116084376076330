import { TimestreamQueryClient, QueryCommand } from "@aws-sdk/client-timestream-query";

// Function to fetch annual totaliser data from AWS Timestream
const fetchAnnualTotalisersData = async () => {
  const client = new TimestreamQueryClient({
    region: "us-east-1",
    credentials: {
      accessKeyId: "AKIARUIVQDAN4QY4OFNB",
      secretAccessKey: "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",
    },
  });

  const query = `
SELECT
      date_trunc('month', time) AS month,
      SUM(CAST(Agg_Total AS double)) AS Agg_Total,
      SUM(CAST(Bit_Total AS double)) AS Bit_Total,
      SUM(CAST(Filler_Total AS double)) AS Filler_Total,
      SUM(CAST(Additive_Total AS double)) AS Additive_Total,
      SUM(CAST(RAP_Total AS double)) AS RAP_Total,
      SUM(CAST(Gas_Total AS double)) AS Gas_Total,
      SUM(CAST(Fuel_Total AS double)) AS Fuel_Total,
      SUM(CAST(Electric_Total AS double)) AS Electric_Total
    FROM "Flexy205-dbv2"."gatewaydata"
    WHERE time >= '2024-01-01 00:00:00' AND time <= '2024-12-31 23:59:59'
    GROUP BY date_trunc('month', time)`;

  let allRows = [];
  let nextToken = null;
  let columnInfo = null;

  try {
    do {
      const params = {
        QueryString: query,
        MaxResults: 100,
        NextToken: nextToken,
      };

      const response = await client.send(new QueryCommand(params));

      if (!columnInfo && response.ColumnInfo) {
        columnInfo = response.ColumnInfo;
      }

      if (response.Rows) {
        allRows = [...allRows, ...response.Rows];
      }

      nextToken = response.NextToken;
    } while (nextToken);

    console.log("Fetched all data rows:", allRows.length);

    // Log raw data before processing
    console.log("Raw data:", allRows);

    return transformQueryResponse({ Rows: allRows, ColumnInfo: columnInfo });
  } catch (error) {
    console.error("Error fetching paginated data:", error);
    return [];
  }
};

// Helper function to transform the response into a usable format
const transformQueryResponse = (response) => {
  if (!response.Rows || response.Rows.length === 0) return [];

  const data = response.Rows.map((row) => {
    const rowData = row.Data.reduce((acc, col, index) => {
      const columnName = response.ColumnInfo[index].Name;
      
      if (columnName === 'month') {
        // Extract month from timestamp (e.g., '2024-12-01 00:00:00.000000000')
        const timestamp = col.ScalarValue;
        const date = new Date(timestamp);
        const formattedMonth = date.toLocaleString('default', { month: 'short' }); // Format as 'Dec 2024'
        acc[columnName] = formattedMonth;
      } else {
        // Round the values to whole numbers where applicable
        acc[columnName] = parseFloat(col.ScalarValue) ? Math.round(parseFloat(col.ScalarValue)) : col.ScalarValue;
      }
      return acc;
    }, {});
    return rowData;
  });

  // Prepare data for graphs or further processing
  const aggregatedData = {
    months: [],
    Agg_Total: [],
    Bit_Total: [],
    Filler_Total: [],
    Additive_Total: [],
    RAP_Total: [],
    Gas_Total: [],
    Fuel_Total: [],
    Electric_Total: [],
  };

  data.forEach((item) => {
    aggregatedData.months.push(item.month || '');
    aggregatedData.Agg_Total.push(item.Agg_Total || 0);
    aggregatedData.Bit_Total.push(item.Bit_Total || 0);
    aggregatedData.Filler_Total.push(item.Filler_Total || 0);
    aggregatedData.Additive_Total.push(item.Additive_Total || 0);
    aggregatedData.RAP_Total.push(item.RAP_Total || 0);
    aggregatedData.Gas_Total.push(item.Gas_Total || 0);
    aggregatedData.Fuel_Total.push(item.Fuel_Total || 0);
    aggregatedData.Electric_Total.push(item.Electric_Total || 0);
  });

  return aggregatedData;
};

export default fetchAnnualTotalisersData;