import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { FormGroup, FormControlLabel, Checkbox, Card, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, CircularProgress } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import VuiPagination from "components/VuiPagination"; // Custom pagination
import Icon from "@mui/material/Icon"; // Icons for pagination arrows
import { useParams } from "react-router-dom";
import { TimestreamQueryClient, QueryCommand } from "@aws-sdk/client-timestream-query";

function DataExport() {
  const { site_id } = useParams(); // Extract site_id from the route
  const [siteData, setSiteData] = useState([]); // State for table data
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(12);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [columnsOptions] = useState([
    { label: "Asphalt Production for the Day", value: "Asphalt_Day" },
    { label: "Asphalt Production for the Week", value: "Asphalt_Week" },
    { label: "Asphalt Production for the Month", value: "Asphalt_Month" },
    { label: "Asphalt Production for the Year", value: "Asphalt_Year" },
    { label: "RAP Total for the Day", value: "RAP_Total_Day" },
    { label: "RAP Total for the Week", value: "RAP_Total_Week" },
    { label: "RAP Total for the Month", value: "RAP_Total_Month" },
    { label: "RAP Total for the Year", value: "RAP_Total_Year" },
    { label: "RAP Utilisation for the Day", value: "RAP_Utilisation_Day" },
    { label: "RAP Utilisation for the Week", value: "RAP_Utilisation_Week" },
    { label: "RAP Utilisation for the Month", value: "RAP_Utilisation_Month" },
    { label: "RAP Utilisation for the Year", value: "RAP_Utilisation_Year" },
    { label: "Electric Total", value: "Electric_Total" },
    { label: "Wasted Electricity", value: "Wasted_Elec" },
    { label: "Wasted Aggregate", value: "Wasted_Agg" },
    { label: "Wasted Fuel", value: "Wasted_Fuel" },
    { label: "Aggregate Total", value: "Agg_Total" },
    { label: "Bitumen Total", value: "Bit_Total" },
    { label: "Filler Total", value: "Filler_Total" },
    { label: "Additive Total", value: "Additive_Total" },
    { label: "RAP Total", value: "RAP_Total" },
    { label: "Gas Total", value: "Gas_Total" },
    { label: "Fuel Total", value: "Fuel_Total" },
  ]);

  const timestreamClient = new TimestreamQueryClient({
    region: "us-east-1",
    credentials: {
      accessKeyId: "AKIARUIVQDAN4QY4OFNB",
      secretAccessKey: "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",
    },
  });

  const fetchSiteData = async () => {
    try {
      setLoading(true);

      const startDateFormatted = new Date(dateRange.startDate).toISOString().replace("T", " ").slice(0, 23);
      const endDateFormatted = new Date(dateRange.endDate).toISOString().replace("T", " ").slice(0, 23);
      const columns = selectedColumns.map((col) => col.value).join(", ");
      const query = `SELECT time, ${columns} FROM "Flexy205-dbv2"."gatewaydata" WHERE time BETWEEN TIMESTAMP '${startDateFormatted}' AND TIMESTAMP '${endDateFormatted}' ORDER BY time DESC LIMIT 100`;

      const command = new QueryCommand({ QueryString: query });
      const data = await timestreamClient.send(command);

      const formattedData = data.Rows.map((row) => {
        const rowData = { "Date Time": new Date(row.Data[0].ScalarValue).toLocaleString() };
        selectedColumns.forEach((col, index) => {
          rowData[col.label] = row.Data[index + 1].ScalarValue;
        });
        return rowData;
      });

      setSiteData(formattedData);
    } catch (error) {
      console.error("Error fetching site data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSubmit = () => {
    fetchSiteData(); // Fetch data only on submit
    handleDialogClose();
  };

  const displayedRows = siteData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  const totalPages = Math.ceil(siteData.length / rowsPerPage);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card>
          <VuiBox display="flex" justifyContent="space-between" alignItems="center">
            <VuiTypography variant="lg" color="white">
              Data Export for Site {site_id}
            </VuiTypography>
            <div>
              <VuiButton variant="contained" color="secondary" onClick={() => setDialogOpen(true)} sx={{ marginRight: 2 }}>
                Edit
              </VuiButton>
              <CSVLink data={siteData} filename="Report.csv" style={{ textDecoration: "none" }}>
                <VuiButton variant="contained" color="primary">
                  Download
                </VuiButton>
              </CSVLink>
            </div>
          </VuiBox>

          {loading ? (
            <VuiBox display="flex" justifyContent="center" py={4}>
              <CircularProgress />
            </VuiBox>
          ) : (
            <Table
              columns={[{ name: "Date Time", align: "left" }, ...selectedColumns.map((col) => ({ name: col.label, align: "center" }))]}
              rows={displayedRows}
            />
          )}

          <VuiBox display="flex" justifyContent="center" p={2}>
            <VuiPagination>
              <VuiPagination item onClick={() => setPage(page - 1)} disabled={page === 1}>
                <Icon>keyboard_arrow_left</Icon>
              </VuiPagination>
              {Array.from({ length: totalPages }, (_, index) => (
                <VuiPagination
                  key={index + 1}
                  item
                  active={page === index + 1}
                  onClick={() => setPage(index + 1)}
                >
                  {index + 1}
                </VuiPagination>
              ))}
              <VuiPagination item onClick={() => setPage(page + 1)} disabled={page === totalPages}>
                <Icon>keyboard_arrow_right</Icon>
              </VuiPagination>
            </VuiPagination>
          </VuiBox>
        </Card>
      </VuiBox>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
  <DialogTitle>Edit Date Range and Data</DialogTitle>
  <DialogContent>
    <Grid container spacing={2}>
      {/* Date Range Fields */}
      <Grid item xs={12}>
        <VuiTypography variant="subtitle1" gutterBottom>
          Select Date Range
        </VuiTypography>
        <TextField
          type="datetime-local"
          label="Start Date"
          fullWidth
          value={dateRange.startDate}
          onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })}
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          type="datetime-local"
          label="End Date"
          fullWidth
          value={dateRange.endDate}
          onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })}
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: 2 }}
        />
      </Grid>

      {/* Column Selection */}
      <Grid item xs={12}>
        <VuiTypography variant="subtitle1" gutterBottom>
          Select Data Columns
        </VuiTypography>
        <FormGroup>
          {columnsOptions.map((col) => (
            <FormControlLabel
              key={col.value}
              control={
                <Checkbox
                  checked={selectedColumns.some((selected) => selected.value === col.value)}
                  onChange={() =>
                    setSelectedColumns((prevSelected) =>
                      prevSelected.some((selected) => selected.value === col.value)
                        ? prevSelected.filter((selected) => selected.value !== col.value)
                        : [...prevSelected, col]
                    )
                  }
                />
              }
              label={col.label}
            />
          ))}
        </FormGroup>
      </Grid>
    </Grid>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleDialogClose} color="primary">
      Cancel
    </Button>
    <Button
      onClick={handleSubmit}
      color="primary"
      disabled={!dateRange.startDate || !dateRange.endDate || selectedColumns.length === 0}
    >
      Submit
    </Button>
  </DialogActions>
</Dialog>

      <Footer />
    </DashboardLayout>
  );
}

export default DataExport;