import { useState } from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import axios from "axios";
import bgForgotPassword from "assets/images/BackGround.png";
import { useNavigate } from "react-router-dom";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSendCode = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      await axios.post(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/check-email",
        { email }
      );
      await axios.post(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/SES-API/send-verification-code",
        { email }
      );

      setMessage("Verification code sent! Check your email.");
      setTimeout(() => navigate(`/reset-password?email=${email}`), 2000);
    } catch (err) {
      setError(err.response?.data?.message || "Failed to send code.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <CoverLayout title="Forgot Password" color="white" image={bgForgotPassword}>
      <VuiBox component="form" onSubmit={handleSendCode}>
        <VuiBox mb={2}>
          <VuiTypography variant="button" color="white" fontWeight="medium">
            Enter your email
          </VuiTypography>
          <VuiInput
            type="email"
            placeholder="Your email..."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </VuiBox>
        {message && <VuiTypography variant="button"
              fontWeight="medium"
              style={{ textAlign: "center" }} color="success">{message}</VuiTypography>}
        {error && <VuiTypography variant="button"
              fontWeight="medium"
              style={{ textAlign: "center" }} color="error">{error}</VuiTypography>}
        <VuiBox mt={4}>
          <VuiButton type="submit" color="info" fullWidth disabled={loading}>
            {loading ? "Sending..." : "Send Verification Code"}
          </VuiButton>
        </VuiBox>
        <VuiBox mt={2}>
          <VuiButton color="secondary" fullWidth onClick={() => navigate("/login")}>
            Return to Login
          </VuiButton>
        </VuiBox>
      </VuiBox>
    </CoverLayout>
  );
}

export default ForgotPassword;