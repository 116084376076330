import React, { useState, useEffect } from "react";
import {
  Card,
  useMediaQuery,
  useTheme,
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import SemiCircleProgress from "./SemiCircleProgress";
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";
import EditIcon from "@mui/icons-material/Edit";

const ProductionCardAsp = ({
  title,
  site_id,
  dayProgress,
  weekProgress,
  color,
  unit,
  monthProgress,
  yearProgress,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const { gradients } = colors;
  const { cardContent } = gradients;
  const [annualTotal, setAnnualTotal] = useState(0);
  const [workDaysPerWeek, setWorkDaysPerWeek] = useState(0);
  const [open, setOpen] = useState(false);
  // Fetch site data from the API
  const fetchSiteData = async () => {
    try {
      const response = await fetch(
        `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/site/profile?site_id=${site_id}`
      );
      const data = await response.json();
      setAnnualTotal(data.annualTotal || 0);
      setWorkDaysPerWeek(data.work_days_per_week || 0);
    } catch (error) {
      console.error("Error fetching site data:", error.message);
    }
  };

  useEffect(() => {
    fetchSiteData();
  }, [site_id]);

  const InfoBox = ({ progress, label, sx }) => (
    <VuiBox
      sx={{
        width: isSmallScreen ? "100px" : "160px",
        padding: isSmallScreen ? "4px 8px" : "8px 12px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        borderRadius: "8px",
        background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
        ...sx,
      }}
    >
      <VuiTypography color="text" variant="caption" display="inline-block">
        0%
      </VuiTypography>
      <VuiBox
        flexDirection="column"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ minWidth: isSmallScreen ? "30px" : "50px" }}
      >
        <VuiTypography color="white" variant="h5" sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}>
          {Math.round(progress)} {unit} {/* Show rounded value */}
        </VuiTypography>
        <VuiTypography color="text" variant="caption" display="inline-block">
          {label}
        </VuiTypography>
      </VuiBox>
      <VuiTypography color="text" variant="caption" display="inline-block">
        100%
      </VuiTypography>
    </VuiBox>
  );

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 1,
        paddingBottom: 0,
      }}
    >
      {/* Edit Button - Positioned in the Top Right Corner */}
      <IconButton
        sx={{ position: "absolute", top: 10, right: 10, color: "#fff", padding: 1 }}
        onClick={() => setOpen(true)}
      >
        <EditIcon />
      </IconButton>
      <VuiBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        p={1}
        sx={{ paddingBottom: 0 }}
      >
        <VuiTypography
          variant="sm"
          color="white"
          fontWeight="bold"
          sx={{
            mb: 0.5,
            fontSize: isSmallScreen ? "0.9rem" : isMediumScreen ? "1rem" : "1.2rem",
          }}
        >
          {title}
        </VuiTypography>

        <VuiBox
          display="flex"
          flexDirection={isSmallScreen ? "column" : "row"}
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
          gap={0.5}
          sx={{ paddingBottom: 0 }}
        >
          {/* Day Progress */}
          <VuiBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              flex: "1 0 auto",
              alignSelf: "center",
            }}
          >
            <VuiBox sx={{ display: "inline-flex" }}>
              <SemiCircleProgress
                percentage={Math.round(dayProgress / annualTotal) || 0} // Rounded value
                size={{
                  width: isSmallScreen ? 160 : 190,
                  height: isSmallScreen ? 150 : 180,
                }}
                strokeWidth={6}
                strokeColor={color}
              />
            </VuiBox>
            <InfoBox
              progress={dayProgress || 0} // Rounded value
              label="Day"
              sx={{
                minWidth: isSmallScreen ? "140px" : "240px",
                marginTop: isSmallScreen ? "-40px" : "-60px",
              }}
            />
          </VuiBox>

          {/* Triangular Layout: Week, Month, Year Progress */}
          <VuiBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexGrow: 1,
              justifyContent: "center",
              paddingBottom: isSmallScreen ? "5px" : "15px",
              alignSelf: "center",
            }}
          >
            {/* Week Progress */}
            <VuiBox
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flexGrow: 1,
                width: "100%",
              }}
            >
              <SemiCircleProgress
                percentage={Math.round(weekProgress / (annualTotal / 52)) || 0} // Rounded value
                size={{
                  width: isSmallScreen ? 100 : 130,
                  height: isSmallScreen ? 100 : 130,
                }}
                strokeWidth={6}
                strokeColor={color}
              />
              <InfoBox
                progress={weekProgress || 0} // Rounded value
                label="Week"
                sx={{ marginTop: isSmallScreen ? "-40px" : "-50px" }}
              />
            </VuiBox>

            {/* Month and Year Progress */}
            <VuiBox
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: 0.5,
                width: "100%",
                flexShrink: 0,
              }}
            >
              <VuiBox sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <SemiCircleProgress
                  percentage={Math.round(monthProgress / (annualTotal / 12)) || 0} // Rounded value
                  size={{
                    width: isSmallScreen ? 100 : 130,
                    height: isSmallScreen ? 100 : 130,
                  }}
                  strokeWidth={6}
                  strokeColor={color}
                />
                <InfoBox
                  progress={monthProgress || 0} // Rounded value
                  label="Month"
                  sx={{ marginTop: isSmallScreen ? "-40px" : "-50px" }}
                />
              </VuiBox>
              <VuiBox sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <SemiCircleProgress
                  percentage={Math.round(yearProgress / annualTotal) || 0} // Rounded value
                  size={{
                    width: isSmallScreen ? 100 : 130,
                    height: isSmallScreen ? 100 : 130,
                  }}
                  strokeWidth={6}
                  strokeColor={color}
                />
                <InfoBox
                  progress={yearProgress || 0} // Rounded value
                  label="Year"
                  sx={{ marginTop: isSmallScreen ? "-40px" : "-50px" }}
                />
              </VuiBox>
              {/* Pop-up for Editing */}
              <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Edit Production Targets</DialogTitle>
                <DialogContent>
                  <TextField
                    label="Annual Total"
                    type="number"
                    value={annualTotal}
                    onChange={(e) => setAnnualTotal(e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Work Days Per Week"
                    type="number"
                    value={workDaysPerWeek}
                    onChange={(e) => setWorkDaysPerWeek(e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpen(false)} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={() => setOpen(false)} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </VuiBox>
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default ProductionCardAsp;
