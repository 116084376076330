import React from 'react';
import Chart from 'react-apexcharts';

const CmLineGraph = ({ title, data }) => {
    const getGradientColors = () => {
        const maxDataValue = Math.max(...data);
        if (maxDataValue > 50) {
            const alarmDuration = data.filter(value => value > 50).length;
            if (alarmDuration > 4) {
                return ['#808080', '#808080', '#808080']; // Set colors to grey for motor data
            } else {
                return ['#808080', '#808080', '#808080']; // Set colors to grey
            }
        }
        return ['#808080', '#808080', '#808080']; // Set colors to grey for healthy state
    };

    const gradientColors = getGradientColors();

    const options = {
        chart: {
            type: 'line',
            height: '350',
            toolbar: {
                show: false
            },
            animations: {
                enabled: false
            }
        },
        stroke: {
            curve: 'smooth',
            width: 4,
            colors: [gradientColors[0]], // Use grey color for the line stroke
        },
        xaxis: {
          labels: {
            style: {
              colors: 'white', // Set Y-axis text color to white
            },
          },
            categories: Array.from({ length: 13 }, (_, i) => i * 2), // Hours in 24 hours (0, 2, ..., 24)
            title: {
                text: 'Hours',
                style: {
                    color: 'white'
                }
            }
        },
        yaxis: {
          labels: {
            style: {
              colors: 'white', // Set Y-axis text color to white
            },
          },
            title: {
                text: 'Percentage',
                style: {
                    color: 'white'
                }
            },
            min: 0,
            max: 100
        },
        grid: {
            borderColor: '#444',
        },
        tooltip: {
            theme: 'dark',
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: 'vertical',
                shadeIntensity: 1,
                gradientToColors: ['#808080', '#808080', '#808080'], // Transition to grey
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 0.5,
                stops: [0, 50, 100] // Stops for grey at all stages
            }
        },
        colors: gradientColors
    };

    const series = [
        {
            name: title,
            data: data,
        }
    ];

    return (
        <div>
            <Chart options={options} series={series} type="line" height={350} />
        </div>
    );
};

export default CmLineGraph;