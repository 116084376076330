import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { Link } from "react-router-dom";

// React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import GradientBorder from "examples/GradientBorder";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgSignIn from "assets/images/BackGround.png";

import axios from "axios";
import { useNavigate } from "react-router-dom";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp > currentTime) {
          navigate("/site-dashboard");
        }
      } catch (err) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
      }
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/login",
        { email, password }
      );
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("email", email);
        localStorage.setItem("user_id", response.data.user_id);
        navigate("/dashboard");
      }
    } catch (err) {
      setError("Invalid login credentials");
    }
  };

  return (
    <CoverLayout title="Nice to see you again!" color="white" image={bgSignIn}>
      <VuiBox component="form" role="form" onSubmit={handleLogin}>
        <VuiBox mb={2}>
          <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
            Email
          </VuiTypography>
          <VuiInput
            type="email"
            placeholder="Your email..."
            fontWeight="500"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </VuiBox>
        <VuiBox mb={2}>
          <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
            Password
          </VuiTypography>
          <VuiInput
            type="password"
            placeholder="Your password..."
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </VuiBox>
        {error && (
          <VuiBox mb={2}>
            <VuiTypography
              variant="button"
              color="error"
              fontWeight="medium"
              style={{ textAlign: "center" }}
            >
              {error}
            </VuiTypography>
          </VuiBox>
        )}
        <VuiBox mt={4} mb={1}>
          <VuiButton type="submit" color="info" fullWidth>
            SIGN IN
          </VuiButton>
        </VuiBox>
        <VuiBox mt={2} textAlign="center">
          <VuiTypography variant="caption" color="white">
            Forgot your password?{" "}
            <Link to="/forgot-password" style={{ color: "white", textDecoration: "none" }}>
              Reset it here
            </Link>
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </CoverLayout>
  );
}

export default SignIn;