// AnnualTotalisersChart.js
import React from 'react';
import ReactApexChart from "react-apexcharts";

const AnnualTotalisersChart = ({ data }) => {
  // Prepare series based on the incoming data
  const series = [
    { name: "Aggregate (kT)", data: data?.Agg_Total || [0] },
    { name: "Bitumen (kT)", data: data?.Bit_Total || [0] },
    { name: "Filler (kT)", data: data?.Filler_Total || [0] },
    { name: "Additive (kT)", data: data?.Additive_Total || [0] },
    { name: "RAP (kT)", data: data?.RAP_Total || [0] },
    { name: "Gas (kWh)", data: data?.Gas_Total || [0] },
    { name: "Fuel (kWh)", data: data?.Fuel_Total || [0] },
    { name: "Electricity (kWh)", data: data?.Electric_Total || [0] },
  ];

  // Chart options
  const options = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
      style: {
        color: "#ffffff", // Tooltip text color
      },
    },
    xaxis: {
      categories: data?.months || [], // Use the transformed months
      labels: {
        style: {
          colors: "#ffffff", // X-axis label color
        },
      },
    },
    yaxis: {
      title: {
        text: "Total",
        style: {
          color: "#ffffff", // Y-axis title color
        },
      },
      labels: {
        formatter: function (value) {
          return Math.round(value); // Round to the nearest whole number
        },
        style: {
          colors: "#ffffff", // Y-axis label color
        },
      },
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      strokeDashArray: 5,
      borderColor: "#56577A",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0,
        opacityFrom: 0.8,
        opacityTo: 0,
      },
    },
    colors: [
      "#0075FF",
      "#2CD9FF",
      "#03045e",
      "#023e8a",
      "#0077b6",
      "#0096c7",
      "#00b4d8",
      "#48cae4",
    ],
    legend: {
      labels: {
        colors: "#ffffff", // Legend text color
      },
    },
  };

  return (
    <div style={{ height: '100%' }}>
      <ReactApexChart options={options} series={series} type="line" height={350} />
    </div>
  );
};

export default AnnualTotalisersChart;