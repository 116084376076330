import React from 'react';
import { Card, useMediaQuery, useTheme } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import CmLineGraph from "./CmLineGraph"; // Adjust the import path as necessary

const ConditionMonitoring = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // Sample data for each graph
    const gearboxVibrationData = [60, 35, 40, 45, 45, 45, 40, 45, 30, 25, 20, 10, 15];
    const gearboxTemperatureData = [20, 25, 30, 35, 45, 40, 40, 45, 45, 45, 60, 60, 65];

    // Removed motor data
    const statusStyle = (status) => ({
        backgroundColor: status === 'Healthy' ? '#808080' : status === 'Warning' ? '#808080' : '#808080',
        padding: '2px 6px',
        borderRadius: '4px',
        display: 'inline-block',
        color: 'white',
        fontSize: '0.75rem',
        whiteSpace: 'nowrap',
    });

    return (
        <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <VuiBox display="flex" flexDirection="column" height="100%" p={2}>
                <VuiTypography variant="lg" color="white" fontWeight="bold">
                    Condition Monitoring
                </VuiTypography>

                <VuiBox
                    sx={{
                        marginTop: '40px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 1,
                        flexGrow: 1,
                        flexDirection: isSmallScreen ? 'column' : 'row', // Flex row for larger screens, column for small screens
                        overflowY: 'auto',
                        paddingBottom: 1,
                    }}
                >
                    {/* Gearboxes Column: Both graphs in one row */}
                    <VuiBox sx={{ flex: 1, padding: 1, borderRadius: 2, minWidth: 0 }}>
                        <VuiTypography
                            variant="h6"
                            color="white"
                            gutterBottom
                            sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}
                        >
                            Gearboxes
                        </VuiTypography>
                        <VuiBox sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', gap: 2 }}>
                            <VuiBox sx={{ flex: 1, padding: 1 }}>
                                <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                                    <VuiTypography color="white">Vibration:</VuiTypography>
                                    <VuiTypography color="white" sx={statusStyle(getLastStatus(gearboxVibrationData))}>
                                        {getLastStatus(gearboxVibrationData)}
                                    </VuiTypography>
                                </VuiBox>
                                <CmLineGraph title="Gearboxes - Vibration" data={gearboxVibrationData} />
                            </VuiBox>
                            <VuiBox sx={{ flex: 1, padding: 1 }}>
                                <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                                    <VuiTypography color="white">Temperature:</VuiTypography>
                                    <VuiTypography color="white" sx={statusStyle(getLastStatus(gearboxTemperatureData))}>
                                        {getLastStatus(gearboxTemperatureData)}
                                    </VuiTypography>
                                </VuiBox>
                                <CmLineGraph title="Gearboxes - Temperature" data={gearboxTemperatureData} />
                            </VuiBox>
                        </VuiBox>
                    </VuiBox>
                </VuiBox>
            </VuiBox>
        </Card>
    );
};

// Function to determine the last status
const getLastStatus = (data) => {
    const lastValue = data[data.length - 1];
    if (lastValue > 50) {
        return lastValue > 50 && data.slice(-5).filter(value => value > 50).length > 4 ? 'Alarm' : 'Warning';
    }
    return 'Healthy';
};

export default ConditionMonitoring;