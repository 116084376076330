import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Card, CircularProgress } from "@mui/material"; // Add CircularProgress for loading indicator
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import PieChart from "layouts/area-dashboard/components/Totals/PieChart"; // Import the pie chart component
import VerticalStackedBarChart from "layouts/area-dashboard/components/Totals/VerticalStackedBarChart"; // Import the stacked bar chart component
import DynamicSiteCard from "layouts/area-dashboard/components/SiteCard"; // Import the DynamicSiteCard
import useAuth from "../../useAuth"; // Import the custom hook
import axios from "axios";

// Virgin Avg Data
import LineChart from "examples/Charts/LineCharts/LineChart";
import { lineChartOptionsDashboard } from "layouts/area-dashboard/data/lineChartOptions";
import { TimestreamQueryClient, QueryCommand } from "@aws-sdk/client-timestream-query";

function AreaDashboard() {
  useAuth();

  const [userSites, setUserSites] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state
  const userToken = localStorage.getItem("token");
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user sites
        const sitesResponse = await axios.get(
          "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/sites",
          {
            headers: { Authorization: `${userToken}`, "Content-Type": "application/json" },
            params: { user_id: userId },
          }
        );

        const sites = sitesResponse.data;

        // Fetch gateway names and Timestream data for each site
        const enrichedSites = await Promise.all(
          sites.map(async (site) => {
            try {
              const gatewayResponse = await fetch(
                `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/gateway/profile?site_id=${site.site_id}`,
                { headers: { Authorization: `${userToken}` } }
              );
              const response = await gatewayResponse.json();
              const gatewayName = response[0].name;

              // Query Timestream for the site's daily and monthly RAP data
              const timestreamClient = new TimestreamQueryClient({
                region: "us-east-1",
                credentials: {
                  accessKeyId: "AKIARUIVQDAN4QY4OFNB",
                  secretAccessKey: "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",
                },
              });

              const dailyQuery = `
                SELECT 
                  Asphalt_Day, RAP_Total_Day, Bit_Total, Fuel_Total, Elec_Per_Ton, Fuel_Per_Ton, 
                  Gas_Per_Ton, Wasted_Agg, Wasted_Elec, Wasted_Fuel, RAP_Total, Asphalt_Year
                FROM "Flexy205-dbv2"."gatewaydata"
                WHERE ThingName = '${gatewayName}'
              `;

              const monthlyQuery = `
                SELECT 
                  month(time) AS month,
                  MAX(RAP_Total) AS RAP_Total_Month
                FROM "Flexy205-dbv2"."gatewaydata"
                WHERE ThingName = '${gatewayName}' AND year(time) = year(current_date)
                GROUP BY month(time)
                ORDER BY month(time)
              `;

              const dailyData = await timestreamClient.send(
                new QueryCommand({ QueryString: dailyQuery })
              );
              const monthlyData = await timestreamClient.send(
                new QueryCommand({ QueryString: monthlyQuery })
              );

              const dailyRow = dailyData.Rows[0]?.Data || [];
              const monthlyDataMap = Array(12).fill(0);
              monthlyData.Rows.forEach((row) => {
                const month = parseInt(row.Data[0]?.ScalarValue, 10);
                const rapTotal = parseFloat(row.Data[1]?.ScalarValue || 0);
                if (month) monthlyDataMap[month - 1] = rapTotal;
              });

              return {
                id: site.id,
                name: site.name,
                gatewayName,
                Asphalt_Day: parseFloat(dailyRow[0]?.ScalarValue || 0),
                RAP_Total_Day: parseFloat(dailyRow[1]?.ScalarValue || 0),
                Bit_Total: parseFloat(dailyRow[2]?.ScalarValue || 0),
                Fuel_Total: parseFloat(dailyRow[3]?.ScalarValue || 0),
                Elec_Per_Ton: parseFloat(dailyRow[4]?.ScalarValue || 0),
                Fuel_Per_Ton: parseFloat(dailyRow[5]?.ScalarValue || 0),
                Gas_Per_Ton: parseFloat(dailyRow[6]?.ScalarValue || 0),
                Wasted_Agg: parseFloat(dailyRow[7]?.ScalarValue || 0),
                Wasted_Elec: parseFloat(dailyRow[8]?.ScalarValue || 0),
                Wasted_Fuel: parseFloat(dailyRow[9]?.ScalarValue || 0),
                RAP_Total: parseFloat(dailyRow[10]?.ScalarValue || 0),
                Asphalt_Year: parseFloat(dailyRow[11]?.ScalarValue || 0),
                RAP_Total_Month: monthlyDataMap,
              };
            } catch (error) {
              console.error(`Error fetching gateway data for site ${site.site_id}:`, error.message);
              return null;
            }
          })
        );

        const successfulSites = enrichedSites.filter((site) => site !== null);
        setUserSites(successfulSites);

        const chartData = successfulSites.map((site) => ({
          name: site.name,
          data: Array.from({ length: 12 }, (_, index) => site.Bit_Total || 0),
        }));
        setLineChartData(chartData);
      } catch (error) {
        console.error("Error fetching sites or data:", error.message);
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false); // Stop loading after fetch
      }
    };

    fetchData();
  }, [userToken]);

  return (
    <DashboardLayout>
        {/* Loading Spinner Overlay */}
        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        )}
        {/* Navbar: Keeps the navbar always on top of the content */}
        <div style={{ zIndex: 10000, position: "relative" }}>
          <DashboardNavbar />
        </div>
        <div style={{ filter: loading ? "blur(5px)" : "none", transition: "filter 0.3s ease" }}>
      <VuiBox py={1}>
        {error && <div style={{ color: "red", padding: "10px" }}>{error}</div>}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card>
              <VuiBox p={1}>
                <VuiTypography variant="h4" color="white" mb={1}>
                  Totals
                </VuiTypography>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <Card sx={{ height: "100%" }}>
                      <VuiBox
                        p={1}
                        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
                      >
                        <PieChart
                          data={
                            userSites.length > 0
                              ? userSites.map((site) => ({
                                  name: site.name,
                                  Asphalt_Year: site.Asphalt_Year || 0,
                                }))
                              : [{ name: "No Data", Asphalt_Year: 0 }]
                          }
                        />
                      </VuiBox>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Card sx={{ height: "100%" }}>
                      <VuiBox
                        p={1}
                        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
                      >
                        <VuiTypography variant="h6" color="white">
                          RAP
                        </VuiTypography>
                        <VerticalStackedBarChart
                          siteData={
                            userSites.length > 0
                              ? userSites.map((site) => ({
                                  name: site.name,
                                  RAP_Total_Month: site.RAP_Total_Month || Array(12).fill(0), // Ensure default to 0
                                }))
                              : [{ name: "No Data", RAP_Total_Month: Array(12).fill(0) }]
                          }
                        />
                      </VuiBox>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Card sx={{ height: "100%" }}>
                      <VuiBox
                        p={1}
                        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
                      >
                        <VuiTypography variant="h6" color="white">
                          Binder
                        </VuiTypography>
                        <LineChart
                          lineChartData={lineChartData}
                          lineChartOptions={lineChartOptionsDashboard}
                        />
                      </VuiBox>
                    </Card>
                  </Grid>
                </Grid>
              </VuiBox>
            </Card>
          </Grid>
        </Grid>
        <VuiBox mt={1}>
          <Grid container spacing={1}>
            {userSites.map((site) => (
              <Grid item xs={12} sm={6} md={4} key={site.id}>
                <DynamicSiteCard site={site} />
              </Grid>
            ))}
          </Grid>
        </VuiBox>
      </VuiBox>
      <Footer />
      </div>
    </DashboardLayout>
  );
}

export default AreaDashboard;
