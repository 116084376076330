import React, { useState, useEffect } from "react";
import { Card } from "@mui/material";
import Box from "@mui/material/Box";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { keyframes } from '@emotion/react';

// Animation for idle state
const idleAnimation = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
`;

function LivePlantStatus({ plantState = "production" }) {
  const initialProgressValues = [75, 50, 25, 40, 60];
  const [progressValues, setProgressValues] = useState([0, 0, 0, 0, 0]);

  // Define media queries
  const isXs = useMediaQuery("(max-width:600px)");
  const isSm = useMediaQuery("(max-width:960px)");
  const isMd = useMediaQuery("(max-width:1280px)");

  // Sizes for each progress bar based on screen size (relative sizes)
  const sizes = isXs ? [0.8, 0.7, 0.6, 0.5, 0.4] : isSm ? [1, 0.9, 0.8, 0.7, 0.6] : [1.2, 1.1, 1, 0.9, 0.8]; // Relative to parent container
  const thickness = isXs ? 6 : 8; // Adjust thickness for smaller screens

  useEffect(() => {
    const interval = setInterval(() => {
      setProgressValues((prevValues) =>
        prevValues.map((value, index) =>
          value < initialProgressValues[index] ? value + 1 : initialProgressValues[index]
        )
      );
    }, 30);

    return () => clearInterval(interval);
  }, [initialProgressValues]);

  // Define gradient colors based on the plant state
  const getGradient = (state) => {
    switch (state) {
      case "production":
        return "#0077C0";
      case "idle":
        return "#BDC3FF";
      case "offline":
        return "#1F316F";
      default:
        return "#0077C0"; // Default to production color
    }
  };

  return (
    <Card sx={{ height: "100%", padding: 2 }}>
      <VuiBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        p={2}
      >
        <VuiTypography variant="lg" color="white" fontWeight="bold">
          Live Plant Status
        </VuiTypography>

        <VuiBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          paddingTop="10px"
          sx={{ flex: 1, width: '100%', position: 'relative' }}
        >
          <VuiBox position="relative" display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
            {sizes.map((size, index) => (
              <svg
                key={index}
                xmlns="http://www.w3.org/2000/svg"
                width={`${size * 100}%`}  // Dynamically scale size
                height={`${size * 100}%`} // Dynamically scale size
                viewBox="0 0 200 200"
                fill="none"
                style={{
                  position: "absolute", // Layer each circle on top of each other
                  maxWidth: "100%", // Prevent overflow
                  maxHeight: "100%", // Prevent overflow
                }}
                color={getGradient(plantState)}
              >
                <defs>
                  <linearGradient id={`spinner-secondHalf-${index}`}>
                    <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
                    <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
                  </linearGradient>
                  <linearGradient id={`spinner-firstHalf-${index}`}>
                    <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
                    <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
                  </linearGradient>
                </defs>

                <g strokeWidth={thickness}>
                  <path stroke={`url(#spinner-secondHalf-${index})`} d="M 4 100 A 96 96 0 0 1 196 100" />
                  <path stroke={`url(#spinner-firstHalf-${index})`} d="M 196 100 A 96 96 0 0 1 4 100" />
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    d="M 4 100 A 96 96 0 0 1 4 98"
                  />
                </g>

                {/* Conditionally apply animation only if plantState is "production" */}
                {plantState === "production" && (
                  <animateTransform
                    from="0 0 0"
                    to="360 0 0"
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1300ms"
                  />
                )}

                {/* Conditionally apply animation only if plantState is "idle" */}
                {plantState === "idle" && (
                  <animateTransform
                    from="0 0 0"
                    to="360 0 0"
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="3000ms"
                  />
                )}
              </svg>
            ))}
            <VuiTypography
              variant="h4"
              component="div"
              sx={{
                position: "relative",
                color: getGradient(plantState),
                fontWeight: "bold",
                zIndex: 10, // Keep the text above the progress bars
              }}
            >
              {plantState.charAt(0).toUpperCase() + plantState.slice(1)}
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

export default LivePlantStatus;