import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import routes from "routes";
import { useVisionUIController, setOpenConfigurator } from "context";
import SiteDashboard from "layouts/site-dashboard"; // Import your SiteDashboard component
import DataExport from "layouts/data-export"; // Import your DataExport component
import axios from "axios";

export default function App() {
  const [controller, dispatch] = useVisionUIController();
  const { direction, layout, openConfigurator } = controller;
  const { pathname } = useLocation();

  const [sites, setSites] = useState([]);
  const token = localStorage.getItem("token");
  const userId= localStorage.getItem("user_id");

  // Fetch the user's sites on app load
  useEffect(() => {
    if (token && userId) {
      const fetchUserSites = async () => {
        try {
          const response = await axios.get(
            "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/sites",
            {
              headers: { Authorization: `${token}`, "Content-Type": "application/json" },
              params: { user_id: userId },
            }
          );
          setSites(response.data);
        } catch (error) {
          console.error("Error fetching sites:", error.message);
        }
      };

      fetchUserSites();
    }
  }, [token, userId]);

  const defaultSiteId = sites.length > 0 ? sites[0].site_id : null;

  // Redirect to the first site in `/site-dashboard` if no `site_id` is present
  useEffect(() => {
    if (defaultSiteId && pathname === "/site-dashboard") {
      window.location.href = `/site-dashboard/${defaultSiteId}`;
    }
  }, [defaultSiteId, pathname]);

  // Redirect to the first site in `/data-export` if no `site_id` is present
  useEffect(() => {
    if (defaultSiteId && pathname === "/data-export") {
      window.location.href = `/data-export/${defaultSiteId}`;
    }
  }, [defaultSiteId, pathname]);

  // Open configurator toggle
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Set the direction for the layout
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Scroll to the top on route change
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (routes) => {
    return routes.map((route) => {
      if (route.type === "collapse") {
        return (
          <Route
            key={route.key}
            path={route.route}
            element={<route.component />}
          />
        );
      }
      return null;
    });
  };

 

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "site-dashboard" && <Configurator />}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        {/* Redirect to the first site for Data Export */}
        <Route path="/data-export/:site_id" element={<DataExport />} />
        <Route path="/data-export" element={defaultSiteId ? <Navigate to={`/data-export/${defaultSiteId}`} /> : null} />
        <Route path="/site-dashboard/:site_id" element={<SiteDashboard />} />
        <Route path="/site-dashboard" element={defaultSiteId ? <Navigate to={`/site-dashboard/${defaultSiteId}`} /> : null} />
        <Route path="*" element={<Navigate to="/site-dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
}