import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Card, Box, Typography, CircularProgress } from "@mui/material";
import Link from "@mui/material/Link";
import ReactApexChart from "react-apexcharts";
import { TimestreamQueryClient, QueryCommand } from "@aws-sdk/client-timestream-query"; 

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProductionCardRAP from "layouts/site-dashboard/components/ProductionCardRAP";
import ProductionCardAsp from "layouts/site-dashboard/components/ProductionCardAsp";
import AnnualPlantStatus from "layouts/site-dashboard/components/AnnualPlantStatus";
import LivePlantStatus from "layouts/site-dashboard/components/LivePlantStatus";
import SiteInformation from "layouts/site-dashboard/components/SiteInformation";
import UsageCard from "layouts/site-dashboard/components/UsageCard";
import StockedMaterials from "layouts/site-dashboard/components/StockedMaterials";
import ConditionMonitoring from "layouts/site-dashboard/components/ConditionMonitoring";
import sharkImage from "assets/images/shark.png";
import { useParams } from "react-router-dom";
import fetchAnnualTotalisersData from "./data/fetchAnnualTotalisersData"; 
import AnnualTotalisersChart from "./data/AnnualTotalisersChart"; 

function Dashboard() {
  const [annualTotalisersData, setAnnualTotalisersData] = useState(null);
  const [stockedMaterialsData, setStockedMaterialsData] = useState([]);
  const [siteData, setSiteData] = useState(null);
  const [timestamp, setTimestamp] = useState(""); 
  const [rapData, setRapData] = useState({ dayProgress: 0, weekProgress: 0, monthProgress: 0, yearProgress: 0 });
  const [asphaltData, setAsphaltData] = useState({ dayProgress: 0, weekProgress: 0, monthProgress: 0, yearProgress: 0 });
  const [usageData, setUsageData] = useState({ unusedAgg: 0, gas: 0, burnerIdleFuel: 0, fuel: 0, electricityOffline: 0, electricity: 0 });
  const [loading, setLoading] = useState(true); 
  const { site_id } = useParams(); 

  // Fetch site data
  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        const response = await fetch(`https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/site/profile?site_id=${site_id}`);
        const data = await response.json();
        setSiteData(data);
      } catch (error) {
        console.error("Error fetching site data:", error.message);
      }
    };

    const fetchGatewayName = async () => {
      try {
        const response = await fetch(`https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/gateway/profile?site_id=${site_id}`);
        const data = await response.json();
        return data[0].name;
      } catch (error) {
        console.error("Error fetching gateway name:", error.message);
        return null;
      }
    };

    const fetchTimestreamData = async () => {
      const gatewayName = await fetchGatewayName(); 

      if (!gatewayName) {
        console.error("Failed to fetch gateway name");
        return;
      }

      const client = new TimestreamQueryClient({
        region: "us-east-1",
        credentials: {
          accessKeyId: "AKIARUIVQDAN4QY4OFNB", 
          secretAccessKey: "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", 
        },
      });

      const combinedQuery = `
        SELECT * FROM "Flexy205-dbv2"."gatewaydata" 
        WHERE ThingName = '${gatewayName}'
        ORDER BY time DESC
        LIMIT 10
      `;

      let nextToken = null;
      let completeData = [];

      try {
        do {
          const response = await client.send(new QueryCommand({
            QueryString: combinedQuery,
            NextToken: nextToken
          }));

          console.log("Timestream Response:", response);

          if (response.Rows && response.Rows.length > 0) {
            const rows = response.Rows;
            rows.forEach(row => {
              const columnData = row.Data.reduce((acc, column, index) => {
                const columnName = response.ColumnInfo[index].Name;
                acc[columnName] = column.ScalarValue;
                return acc;
              }, {});

              completeData.push(columnData);
            });
          }

          nextToken = response.NextToken; 
        } while (nextToken); 

        // Process the complete data after all pages are fetched
        if (completeData.length > 0) {
          const materials = [];
          completeData.forEach(columnData => {
            Object.keys(columnData).forEach((key) => {
              if (key.startsWith("SM_Name_")) {
                const index = key.split("_")[2];
                const totalKey = `SM_Total_${index}`;
                if (columnData[totalKey] !== undefined) {
                  materials.push({
                    name: columnData[key],
                    total: columnData[totalKey],
                    index: index,
                  });
                }
              }
            });
          });

          materials.sort((a, b) => a.index - b.index);
          setStockedMaterialsData(materials);

          const rapColumnData = completeData[0];
          setRapData({
            dayProgress: rapColumnData["RAP_Utilisation_Day"] ?? 0,
            weekProgress: rapColumnData["RAP_Utilisation_Week"] ?? 0,
            monthProgress: rapColumnData["RAP_Utilisation_Month"] ?? 0,
            yearProgress: rapColumnData["RAP_Total"] ?? 0,
            maxDayValue: rapColumnData["RAP_Total_Day"] ?? 0,
            maxWeekValue: rapColumnData["RAP_Total_Week"] ?? 0,
            maxMonthValue: rapColumnData["RAP_Total_Month"] ?? 0,
            maxYearValue: rapColumnData["RAP_Total_Year"] ?? 0,
          });

          setAsphaltData({
            dayProgress: rapColumnData["Asphalt_Day"] ?? 0,
            weekProgress: rapColumnData["Asphalt_Week"] ?? 0,
            monthProgress: rapColumnData["Asphalt_Month"] ?? 0,
            yearProgress: rapColumnData["Asphalt_Year"] ?? 0,
          });

          setUsageData({
            unusedAgg: rapColumnData["Wasted_Agg"] ?? 0,
            gas: rapColumnData["Gas_Per_Ton"] ?? 0,
            burnerIdleFuel: rapColumnData["Wasted_Fuel"] ?? 0,
            fuel: rapColumnData["Fuel_Per_Ton"] ?? 0,
            electricityOffline: rapColumnData["Wasted_Elec"] ?? 0,
            electricity: rapColumnData["Elec_Per_Ton"] ?? 0,
          });

          setTimestamp(new Date(rapColumnData["time"]).toLocaleString());
        } else {
          console.error("No data found in response.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchData = async () => {
      const data = await fetchAnnualTotalisersData();
      setAnnualTotalisersData(data);
    };

    fetchData();
    fetchSiteData();
    fetchTimestreamData();
  }, [site_id]);

  return (
    <DashboardLayout>
    {/* Loading Spinner Overlay */}
    {loading && (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    )}
    {/* Navbar: Keeps the navbar always on top of the content */}
    <div style={{ zIndex: 10000, position: "relative" }}>
      <DashboardNavbar />
    </div>
    <div style={{ filter: loading ? "blur(5px)" : "none", transition: "filter 0.3s ease" }}>

        <VuiBox mb={1}>
          <Grid container spacing={1}>
            {/* Site Information Component */}
            <Grid item xs={12} sm={6} md={3}>
              <SiteInformation siteId={site_id} siteData={siteData} timestamp={timestamp} />
            </Grid>
            {/* Annual Plant Status Component */}
            <Grid item xs={12} sm={6} md={3}>
              <AnnualPlantStatus />
            </Grid>
            {/* Live Plant Status Component */}
            <Grid item xs={12} sm={6} md={3}>
              <LivePlantStatus plantState="idle" />
            </Grid>
            {/* Shark Image Card */}
            <Grid item xs={12} sm={6} md={3}>
              <Card
                sx={{
                  height: "100%",
                  position: "relative",
                  overflow: "hidden",
                  borderRadius: "10px",
                }}
              >
                <Link
                  href="https://sharkesms.com/"
                  target="_blank"
                  rel="noopener"
                  sx={{ display: "block", width: "100%", height: "100%" }}
                >
                  <VuiBox
                    sx={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={sharkImage}
                      alt="Shark"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        filter: "brightness(50%)", // Optional: adjust brightness for tinting
                      }}
                    />
                    <VuiBox
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.3)", // Overlay color
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 1, // Ensure text is above the image
                      }}
                    >
                      <VuiTypography variant="h6" color="white" fontWeight="bold">
                        Visit Shark EMS
                      </VuiTypography>
                    </VuiBox>
                  </VuiBox>
                </Link>
              </Card>
            </Grid>
          </Grid>
        </VuiBox>
        {/* Production Cards Section */}
        <VuiBox mb={1}>
          <Grid container spacing={1}>
            {/* Asphalt Production Card */}
            <Grid item xs={12} md={6}>
              <ProductionCardAsp
                site_id={site_id}
                title="Asphalt"
                dayProgress={asphaltData.dayProgress}
                weekProgress={asphaltData.weekProgress}
                monthProgress={asphaltData.monthProgress}
                yearProgress={asphaltData.yearProgress}
                color="#0075FF"
                unit="tons"
              />
            </Grid>
            {/* RAP Production Card */}
            <Grid item xs={12} md={6}>
              <ProductionCardRAP
                title="RAP"
                RAP_Utilisation_Day={rapData.dayProgress}
                RAP_Utilisation_Week={rapData.weekProgress}
                RAP_Utilisation_Month={rapData.monthProgress}
                RAP_Total={rapData.yearProgress}
                color="#D5ED9F"
                unit="T"
                maxDayValue={rapData.maxDayValue}
                maxWeekValue={rapData.maxWeekValue}
                maxMonthValue={rapData.maxMonthValue}
                maxYearValue={rapData.maxYearValue}
              />
            </Grid>
          </Grid>
        </VuiBox>

        <VuiBox mb={1}>
          <Grid container spacing={1}>
            {/* Usage live data */}
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <UsageCard
                title="Unused Agg."
                color="#5E5CE6"
                value={usageData.unusedAgg} // Use the value from Timestream
                maxValue={0.1}
                unit="T / yr"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <UsageCard
                title="Gas Per Tonnage"
                color="#73CAC5"
                value={usageData.gas} // Use the value from Timestream
                maxValue={0.1}
                unit="kWh"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <UsageCard
                title="Burner Idle Fuel"
                color="#C63C51"
                value={usageData.burnerIdleFuel} // Use the value from Timestream
                maxValue={0.1}
                unit="kWh"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <UsageCard
                title="Fuel"
                color="#CAB773"
                value={usageData.fuel} // Use the value from Timestream
                maxValue={0.1}
                unit="kWh"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <UsageCard
                title="Electricity per Tonnage"
                color="#F0F477"
                value={usageData.electricity} // Use the value from Timestream
                maxValue={0.1}
                unit="kWh"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <UsageCard
                title="Electricity Offline"
                color="#F0A8D0"
                value={usageData.electricityOffline} // Use the value from Timestream
                maxValue={0.1}
                unit="kWh"
              />
            </Grid>
          </Grid>
        </VuiBox>

        {/* Line Chart Section */}
        <VuiBox mb={1}>
          <Grid container spacing={1}>
            {/* Annual Totalisers */}
            <Grid item xs={12} lg={12} xl={6}>
              <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                <VuiBox display="flex" flexDirection="column" height="100%" p={2}>
                  <VuiTypography variant="lg" color="white" fontWeight="bold">
                    Annual Totalisers
                  </VuiTypography>
                  <VuiBox display="flex" alignItems="center" mb="40px">
                    {/* <VuiTypography variant="button" color="success" fontWeight="bold">
                      +5% more{" "}
                      <VuiTypography variant="button" color="text" fontWeight="regular">
                        in 2024
                      </VuiTypography>
                    </VuiTypography> */}
                  </VuiBox>
                  <VuiBox sx={{ height: "100%" }}>
                    <AnnualTotalisersChart data={annualTotalisersData} />
                  </VuiBox>
                </VuiBox>
              </Card>
            </Grid>

            {/* Condition Monitoring */}
            <Grid item xs={12} lg={12} xl={3} sx={{ minHeight: "350px" }}>
              <ConditionMonitoring />
            </Grid>
            {/* Stocked Materials */}
            <Grid item xs={12} lg={12} xl={3} sx={{ minHeight: "350px" }}>
              <StockedMaterials materials={stockedMaterialsData} />{" "}
            </Grid>
          </Grid>
        </VuiBox>
      <Footer />
      </div>
    </DashboardLayout>
  );
}

export default Dashboard;