import React from 'react';
import { Card } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import colors from 'assets/theme/base/colors';

const AnnualPlantStatus = () => {
  const { gradients } = colors;
  const { cardContent } = gradients;

  const data = [
    { id: 0, label: 'Offline Hours', value: 192, color: '#1F316F' },
    { id: 1, label: 'Production Hours', value: 100, color: '#0077C0' },
    { id: 2, label: 'Idle Hours', value: 95, color: '#BDC3FF' },
    { id: 3, label: 'Other Hours', value: 92, color: '#C7EEFF' },
  ];

  return (
    <Card sx={{ height: '100%', padding: 2 }}>
      <VuiBox display="flex" flexDirection="column" height="100%" p={2}>
        <VuiTypography variant="lg" color="white" fontWeight="bold">
          Annual Plant Status
        </VuiTypography>
        <VuiBox display="flex" justifyContent="space-between" alignItems="center" mt={3}>
          {/* Pie Chart */}
          <VuiBox flex={1} sx={{ width: '100%' }}>
            <PieChart
              series={[
                {
                  data: data.map((item) => ({
                    value: item.value,
                    label: item.label,
                    color: item.color,
                  })),
                },
              ]}
              slotProps={{
                legend: { hidden: true },
              }}
              height={100} // Adjust height as needed
              width={200} // Adjust height as needed

            />
          </VuiBox>

          {/* Custom Legend */}
          <VuiBox display="flex" flexDirection="column" flex={1} p={1}>
            {data.map((item) => (
              <VuiBox display="flex" alignItems="center" mb={1} key={item.id}>
                <VuiBox
                  sx={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor: item.color,
                    mr: 2,
                  }}
                />
                <VuiTypography variant="caption" color="text">
                  {item.label}: {item.value} hours
                </VuiTypography>
              </VuiBox>
            ))}
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default AnnualPlantStatus;