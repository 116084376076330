import React from "react";
import { Card } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

const StockedMaterials = ({ materials }) => {
  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <VuiBox display="flex" flexDirection="column" height="100%" p={2}>
        <VuiTypography
          variant="lg"
          color="white"
          fontWeight="bold"
          sx={{
            mb: 1,
          }}
        >
          Stocked Materials
        </VuiTypography>

        <VuiBox sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
          {materials.length > 0 ? (
            materials.map((material, index) => (
              <VuiBox
                key={index}
                sx={{
                  backgroundColor: "primary.main",
                  padding: 2,
                  borderRadius: 2,
                  flex: "1 1 calc(33.333% - 16px)", // 3 items per row
                  textAlign: "center",
                  boxSizing: "border-box",
                }}
              >
                <VuiTypography variant="h6" color="white">
                  {material.name}
                </VuiTypography>
                <VuiTypography variant="body1" color="white">
                  {material.total}
                </VuiTypography>
              </VuiBox>
            ))
          ) : (
            <VuiTypography variant="body1" color="white">
              No materials available
            </VuiTypography>
          )}
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default StockedMaterials;