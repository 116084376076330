import React from "react";
import ReactApexChart from "react-apexcharts";

const VerticalStackedBarChart = ({ siteData }) => {
  const series = siteData.map((site) => ({
    name: site.name,
    data: site.RAP_Total_Month || Array(12).fill(0),
  }));

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: { show: false },
    },
    colors: ["#14B8A6", "#3B82F6", "#6366F1", "#EC4899", "#F59E0B", "#FACC15"],
    xaxis: {
      categories: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      labels: { style: { colors: "white" } },
    },
    yaxis: {
      labels: { style: { colors: "white" } },
    },
    legend: {
      show: true,
      position: "top",
      labels: { colors: "white" },
    },
    dataLabels: { enabled: false },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        columnWidth: "45%",
      },
    },
  };

  return (
    <div>
      <ReactApexChart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default VerticalStackedBarChart;