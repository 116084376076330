// @mui/material components
import { Card } from "@mui/material";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';

// Register ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend, Title);

// PieChart Component
const PieChart = ({ data: siteData }) => {
  // Calculate total asphalt for percentage calculations
  const totalAsphalt = siteData.reduce((acc, site) => acc + site.Asphalt_Year, 0);

  // Prepare data for Pie Chart
  const data = {
    labels: siteData.map((site) => site.name),
    datasets: [
      {
        data: siteData.map((site) => site.Asphalt_Year),
        backgroundColor: [
          "#14B8A6", "#3B82F6", "#6366F1", "#EC4899", "#F59E0B", "#FACC15",
        ],
        hoverBackgroundColor: [
          "#14B8A6", "#3B82F6", "#6366F1", "#EC4899", "#F59E0B", "#FACC15",
        ],
        borderColor: '#ffffff',
        borderWidth: 2,
        cutout: '70%', // Create donut effect
      },
    ],
  };

  // Pie chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false }, // Disable default legend
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw;
            const percentage = ((value / totalAsphalt) * 100).toFixed(2);
            return `${context.label}: ${value} tons (${percentage}%)`;
          },
        },
      },
    },
  };

  return (
    <Card sx={{ padding: 2, height: '100%' }}>
      <VuiBox display="flex" flexDirection="column" justifyContent="space-between" height="100%">
        <VuiTypography variant="h6" fontWeight="bold" color="white" mb={2}>
          Asphalt (Yearly)
        </VuiTypography>

        <VuiBox display="flex" justifyContent="space-between" alignItems="center" sx={{ flexGrow: 1 }}>
          {/* Pie Chart */}
          <VuiBox sx={{ position: 'relative', width: '45%', height: '300px' }}>
            <Pie data={data} options={options} />
          </VuiBox>

          {/* Index Table */}
          <VuiBox display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" sx={{ width: '45%' }}>
            {siteData.map((site, index) => (
              <VuiBox display="flex" alignItems="center" mb={1} key={index}>
                <VuiBox
                  sx={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor: data.datasets[0].backgroundColor[index],
                    mr: 2,
                  }}
                />
                <VuiTypography variant="caption" color="white">
                  {site.name}: {site.Asphalt_Year} tons ({((site.Asphalt_Year / totalAsphalt) * 100).toFixed(2)}%)
                </VuiTypography>
              </VuiBox>
            ))}
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default PieChart;