import { useState, useEffect } from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import axios from "axios";
import bgResetPassword from "assets/images/BackGround.png";
import { useNavigate, useLocation } from "react-router-dom";

function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState(new URLSearchParams(location.search).get("email") || "");
  const [verificationCode, setVerificationCode] = useState("");
  const [password, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isResending, setIsResending] = useState(false);
  const [timer, setTimer] = useState(300);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    if (!email) navigate("/forgot-password");

    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(countdown);
          setCanResend(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [email, navigate]);

  const validatePassword = (password) =>
    /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError("");

    if (!validatePassword(password)) {
      setError("Password must have 8+ characters, a capital letter, and a special character.");
      return;
    }

    try {
      await axios.post(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/verify-code",
        { email, verification_code: verificationCode },
        { headers: { Authorization: `${localStorage.getItem("token")}` } }
      );

      await axios.put(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/update/password",
        { email, password },
        { headers: { Authorization: `${localStorage.getItem("token")}` } }
      );

      setMessage("Password reset successful! Redirecting...");
      setTimeout(() => navigate("/sign-in"), 2000);
    } catch (err) {
      setError(err.response?.data?.message || "Failed to reset password.");
    }
  };

  const handleResendCode = async () => {
    if (!canResend) return;

    setIsResending(true);
    try {
      await axios.post(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/SES-API/send-verification-code",
        { email },
        { headers: { Authorization: `${localStorage.getItem("token")}` } }
      );
      setMessage("Verification code resent successfully.");
      setTimer(300);
      setCanResend(false);
    } catch (err) {
      setError("Failed to resend verification code.");
    } finally {
      setIsResending(false);
    }
  };

  return (
    <CoverLayout title="Reset Password" color="white" image={bgResetPassword}>
      <VuiBox component="form" onSubmit={handleResetPassword}>
        <VuiBox mb={2}>
          <VuiTypography variant="button" color="white" fontWeight="medium">
            Email
          </VuiTypography>
          <VuiInput type="email" value={email} disabled />
        </VuiBox>
        <VuiBox mb={2}>
          <VuiTypography variant="button" color="white" fontWeight="medium">
            Verification Code
          </VuiTypography>
          <VuiInput
            type="text"
            placeholder="Enter code..."
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
        </VuiBox>
        <VuiBox mb={2}>
          <VuiTypography variant="button" color="white" fontWeight="medium">
            New Password
          </VuiTypography>
          <VuiInput
            type="password"
            placeholder="Enter new password..."
            value={password}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </VuiBox>
        {message && (
          <VuiTypography
            variant="button"
            fontWeight="medium"
            style={{ textAlign: "center" }}
            color="success"
          >
            {message}
          </VuiTypography>
        )}
        {error && (
          <VuiTypography
            variant="button"
            fontWeight="medium"
            style={{ textAlign: "center" }}
            color="error"
          >
            {error}
          </VuiTypography>
        )}
        <VuiBox mt={4}>
          <VuiButton type="submit" color="info" fullWidth>
            Reset Password
          </VuiButton>
        </VuiBox>
        <VuiBox mt={2}>
          <VuiButton
            color="secondary"
            fullWidth
            onClick={handleResendCode}
            disabled={isResending || !canResend}
          >
            {isResending
              ? "Resending..."
              : canResend
              ? "Resend Code"
              : `Resend in ${Math.floor(timer / 60)}:${timer % 60}`}
          </VuiButton>
        </VuiBox>
      </VuiBox>
    </CoverLayout>
  );
}

export default ResetPassword;
